<template>
  <div>
    <!-- <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb> -->
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="商品管理列表" name="first">
        <goodsList />
      </el-tab-pane>
      <el-tab-pane label="发布商品" name="second">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <div class="el-form-first">
            <h3 class="el-form-first_h3">基础信息</h3>
            <el-form-item label="商品名称 " prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入商品名称（中文）"
                maxlength="60"
                style="width: 80%"
                show-word-limit
              ></el-input>
              <el-input
                v-model="form.nameTw"
                placeholder="请输入商品名称（繁体）"
                maxlength="60"
                style="width: 80%"
                show-word-limit
              ></el-input>
              <el-input
                v-model="form.nameUs"
                placeholder="请输入商品名称（英文）"
                maxlength="60"
                style="width: 80%"
                show-word-limit
              ></el-input>
              <el-input
                v-model="form.nameKr"
                placeholder="请输入商品名称（韩文）"
                maxlength="60"
                style="width: 80%"
                show-word-limit
              ></el-input>
              <el-input
                v-model="form.nameJp"
                placeholder="请输入商品名称（日文）"
                maxlength="60"
                style="width: 80%"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="商品价格 " prop="price">
              <el-input-number
                v-model="form.price"
                :precision="2"
                :step="0.1"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="精选商品 " prop="checked">
              <el-checkbox v-model="form.checked">设为精选</el-checkbox>
            </el-form-item>
          </div>

          <div class="el-form-first" style="margin-top: 10px">
            <h3 class="el-form-first_h3">图文描述</h3>

            <el-form-item label="主图图片 " prop="img">
              <p style="color: #000000; opacity: 0.5">
                上传1:1的图片,大小不能超过 10MB（默认第一张图片为主图）
              </p>

              <el-upload
                :action="UploadUrl()"
                ref="uploadimg"
                :limit="5"
                :auto-upload="false"
                :on-change="imageChange"
                :show-file-list="true"
                :file-list="fileList"
                list-type="picture-card"
                :on-exceed="handleExceed"
                :before-remove="handleRemove"
                :class="{ disabled: uploadDisabled }"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>

            <el-form-item label="详情描述（中文） " prop="detail">
              <div id="richText"></div>
              <div class="el-form_btn_b_save">
                <el-button @click="handleSaveShelves('form', 'zh')"
                  >保存草稿</el-button
                >
                <div class="el-form_btn_b_save_div" v-if="isSave">
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    size="mini"
                    circle
                  ></el-button
                  >最后保存于{{ getTimeSave }}
                </div>
              </div>
            </el-form-item>
            <el-form-item label="详情描述（繁体） " prop="detail">
              <div id="richText2"></div>
              <div class="el-form_btn_b_save">
                <el-button @click="handleSaveShelves('form', 'tw')"
                  >保存草稿</el-button
                >
                <div class="el-form_btn_b_save_div" v-if="isSaveTw">
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    size="mini"
                    circle
                  ></el-button
                  >最后保存于{{ getTimeSaveTw }}
                </div>
              </div>
            </el-form-item>
            <el-form-item label="详情描述（英文） " prop="detail">
              <div id="richText3"></div>
              <div class="el-form_btn_b_save">
                <el-button @click="handleSaveShelves('form', 'us')"
                  >保存草稿</el-button
                >
                <div class="el-form_btn_b_save_div" v-if="isSaveUs">
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    size="mini"
                    circle
                  ></el-button
                  >最后保存于{{ getTimeSaveUs }}
                </div>
              </div>
            </el-form-item>
            <el-form-item label="详情描述（韩文） " prop="detail">
              <div id="richText4"></div>
              <div class="el-form_btn_b_save">
                <el-button @click="handleSaveShelves('form', 'kr')"
                  >保存草稿</el-button
                >
                <div class="el-form_btn_b_save_div" v-if="isSaveKr">
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    size="mini"
                    circle
                  ></el-button
                  >最后保存于{{ getTimeSaveKr }}
                </div>
              </div>
            </el-form-item>
            <el-form-item label="详情描述（日文） " prop="detail">
              <div id="richText5"></div>
              <div class="el-form_btn_b_save">
                <el-button @click="handleSaveShelves('form', 'jp')"
                  >保存草稿</el-button
                >
                <div class="el-form_btn_b_save_div" v-if="isSaveJp">
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    size="mini"
                    circle
                  ></el-button
                  >最后保存于{{ getTimeSaveJp }}
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="el-form-first" style="margin-top: 10px">
            <h3 class="el-form-first_h3">商品状态</h3>

            <el-form-item label="上架时间 " prop="time">
              <el-radio v-model="form.radio" label="true">立即上架</el-radio>
              <el-radio v-model="form.radio" label="false">放入仓库</el-radio>
            </el-form-item>

            <el-divider style="margin-top: 20px"></el-divider>

            <div class="el-form_btn_b">
              <el-button type="primary" @click="handleRelease('form')"
                >提交商品信息</el-button
              >
            </div>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="仓库" name="third">
        <wareHouse />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";
import wareHouse from "./warehouse.vue";
import goodsList from "./list.vue";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import E from "wangeditor"; //引入富文本插件
let editor;
let editor2;
let editor3;
let editor4;
let editor5;

export default {
  components: {
    Breadcrumb,
    Pagination,
    wareHouse,
    goodsList,
  },
  inject: ["reload"],
  data() {
    let validImg = (rule, value, callback) => {
      console.log(value, 1);
      console.log(this.fileList, 2);
      if (this.fileListImg.length < 1) {
        callback(new Error("请至少上传一张图片"));
      } else {
        callback();
      }
    };
    return {
      BreadcrumbCon: [
        {
          name: "趣宝商城",
        },
        {
          name: "商品上架管理",
        },
      ], // 面包屑数据

      form: {
        name: "",
        nameTw: "",
        nameUs: "",
        nameKr: "",
        nameJp: "",
        price: "",
        checked: false,
        radio: "true",
      },

      rules: {
        // name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        img: [
          {
            required: true,
            validator: validImg,
          },
        ],
        // time: [{ required: true, message: "请选择上架时间", trigger: "blur" }],
      },

      fileList: [],
      fileListImg: [],
      activeName: "first",

      isSave: false,
      isSaveTw: false,
      isSaveKr: false,
      isSaveJp: false,
      isSaveUs: false,
      getTimeSave: "",
      getTimeSaveTw: "",
      getTimeSaveKr: "",
      getTimeSaveJp: "",
      getTimeSaveUs: "",

      tableData: [],

      uploadDisabled: false,
    };
  },

  mounted() {
    let type = this.$route.query.type;
    //通过拿到的值不同，更改activeName的值
    if (type === "1") {
      this.activeName = "first";
    } else if (type === "2") {
      this.activeName = "second";
    } else if (type === "3") {
      this.activeName = "third";
    }

    setTimeout(() => {
      // 中文
      editor = new E("#richText");
      editor.config.uploadImgServer = reqUrl.richImageUpload;
      editor.config.uploadFileName = "file";
      editor.create();
      editor.txt.html("");

      // 繁体
      editor2 = new E("#richText2");
      editor2.config.uploadImgServer = reqUrl.richImageUpload;
      editor2.config.uploadFileName = "file";
      editor2.create();
      editor2.txt.html("");

      // 英文
      editor3 = new E("#richText3");
      editor3.config.uploadImgServer = reqUrl.richImageUpload;
      editor3.config.uploadFileName = "file";
      editor3.create();
      editor3.txt.html("");

      // 韩文
      editor4 = new E("#richText4");
      editor4.config.uploadImgServer = reqUrl.richImageUpload;
      editor4.config.uploadFileName = "file";
      editor4.create();
      editor4.txt.html("");

      // 日文
      editor5 = new E("#richText5");
      editor5.config.uploadImgServer = reqUrl.richImageUpload;
      editor5.config.uploadFileName = "file";
      editor5.create();
      editor5.txt.html("");
    }, 100);
  },

  methods: {
    handleTabClick(tab) {
      let queryType;
      if (tab.name == "first") {
        queryType = 1;
      } else if (tab.name == "second") {
        queryType = 2;
      } else if (tab.name == "third") {
        queryType = 3;
      }
      //将新的值和url放在一起拼成一个完整的路径
      this.$router.push({
        query: {
          type: queryType || 1,
        },
      });
    },

    UploadUrl() {
      return reqUrl.imageUpload;
    },

    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChange(file, fileList) {
      if (fileList.length >= 5) {
        this.uploadDisabled = true;
      }
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          localStorage.setItem("shelvesImgUrl", res.data.data.url);

          this.fileListImg.push(res.data.data.url);
          console.log(this.fileListImg, 3);
          // console.log(this.fileListImg);
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    //超出限制个数钩子
    handleExceed(files) {
      this.$message.warning("当前限制选择5个文件");
    },

    // 删除图片
    handleRemove(file, fileList) {
      const index = fileList.findIndex((item) => {
        return item.uid === file.uid;
      });
      this.fileListImg.splice(index, 1);
    },

    handleSaveShelves(formName, lang) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (lang == "zh") {
            const opt = {
              url: reqUrl.goodsStringToFileUploadUrl,
              method: "POST",
              params: JSON.stringify({
                fileString: editor.txt.html(),
                title: this.form.name,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.isSave = true;
                  this.getTimeSave = new Date().toLocaleString();

                  localStorage.setItem("htmlUrlShelvesZh", res.data.data.url);
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          } else if (lang == "tw") {
            const opt = {
              url: reqUrl.goodsStringToFileUploadUrl,
              method: "POST",
              params: JSON.stringify({
                fileString: editor2.txt.html(),
                title: this.form.nameTw,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.isSaveTw = true;
                  this.getTimeSaveTw = new Date().toLocaleString();

                  localStorage.setItem("htmlUrlShelvesTw", res.data.data.url);
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          } else if (lang == "us") {
            const opt = {
              url: reqUrl.goodsStringToFileUploadUrl,
              method: "POST",
              params: JSON.stringify({
                fileString: editor3.txt.html(),
                title: this.form.nameUs,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.isSaveUs = true;
                  this.getTimeSaveUs = new Date().toLocaleString();

                  localStorage.setItem("htmlUrlShelvesUs", res.data.data.url);
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          } else if (lang == "kr") {
            const opt = {
              url: reqUrl.goodsStringToFileUploadUrl,
              method: "POST",
              params: JSON.stringify({
                fileString: editor4.txt.html(),
                title: this.form.nameKr,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.isSaveKr = true;
                  this.getTimeSaveKr = new Date().toLocaleString();

                  localStorage.setItem("htmlUrlShelvesKr", res.data.data.url);
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          } else if (lang == "jp") {
            const opt = {
              url: reqUrl.goodsStringToFileUploadUrl,
              method: "POST",
              params: JSON.stringify({
                fileString: editor5.txt.html(),
                title: this.form.nameJp,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.isSaveJp = true;
                  this.getTimeSaveJp = new Date().toLocaleString();

                  localStorage.setItem("htmlUrlShelvesJp", res.data.data.url);
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          }
        } else {
          this.$message.error("请检查填写信息是否有误哦！");
          return false;
        }
      });
    },

    handleRelease(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("请确认是否已保存“详情描述”，确认后请继续", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            showLoading();
            const opt = {
              url: reqUrl.addGoodsURL,
              method: "POST",
              params: JSON.stringify({
                goodsName: this.form.name, // 商品名称
                goodsNameTw: this.form.nameTw, // 商品名称fan
                goodsNameKr: this.form.nameKr, // 商品名称han
                goodsNameJp: this.form.nameJp, // 商品名称ri
                goodsNameUs: this.form.nameUs, // 商品名称ying
                goodsPrice: this.form.price, // 商品价格
                goodsState: this.form.radio, // 商品状态
                primaryImage: this.fileListImg[0], // 主页图片
                isRecommend: this.form.checked, // 是否精选
                goodsDesc: localStorage.getItem("htmlUrlShelvesZh"), // 商品详情
                goodsDescTw: localStorage.getItem("htmlUrlShelvesTw"), // 商品详情fan
                goodsDescKr: localStorage.getItem("htmlUrlShelvesKr"), // 商品详情han
                goodsDescJp: localStorage.getItem("htmlUrlShelvesJp"), // 商品详情ri
                goodsDescUs: localStorage.getItem("htmlUrlShelvesUs"), // 商品详情ying
                goodsDescEdit: editor.txt.html(),
                goodsDescTwEdit: editor2.txt.html(),
                goodsDescUsEdit: editor3.txt.html(),
                goodsDescKrEdit: editor4.txt.html(),
                goodsDescJpEdit: editor5.txt.html(),
                imageUrl1: this.fileListImg[0], // 图片1
                imageUrl2: this.fileListImg[1] || "", // 图片2
                imageUrl3: this.fileListImg[2] || "", // 图片3
                imageUrl4: this.fileListImg[3] || "", // 图片4
                imageUrl5: this.fileListImg[4] || "", // 图片5
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "发布商品成功",
                    type: "success",
                  });
                  this.activeName = "second";
                  localStorage.removeItem("htmlUrlShelvesZh");
                  localStorage.removeItem("htmlUrlShelvesTw");
                  localStorage.removeItem("htmlUrlShelvesKr");
                  localStorage.removeItem("htmlUrlShelvesJp");
                  localStorage.removeItem("htmlUrlShelvesUs");
                  this.refresh();
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          });
        } else {
          this.$message.error("请检查填写信息是否有误哦！");
          return false;
        }
      });
    },

    handlePageChange(data) {},

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__content {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

::v-deep .el-form {
  .el-form-first {
    background: #fff;
    padding: 10px;

    .el-form-first_h3 {
      margin: 0 0 20px 10px;
    }

    .el-divider--horizontal {
      margin: 35px 0;
    }

    .el-form_btn_b {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
    }

    .el-input {
      margin-bottom: 10px;
    }
  }

  .el-form-item__content {
    z-index: 111;
  }
}

::v-deep .el-tabs__item {
  outline: none;
}
</style>
